import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "./component/ui/loader";
import { register } from "./config/sw_register";

//pages
const Login=lazy(()=>(import("./pages/login")))
const Register=lazy(()=>(import("./pages/register")))
const Dashboard=lazy(()=>(import("./pages/admin/dashboard")))
const Profile=lazy(()=>(import("./pages/admin/profile")))
const Jabatan=lazy(()=>(import("./pages/admin/jabatan")))
const AddJabatan=lazy(()=>(import("./pages/admin/jabatan/add")))
const Users=lazy(()=>(import("./pages/admin/users")))
const AddUser=lazy(()=>(import("./pages/admin/users/add")))
const EditUser=lazy(()=>(import("./pages/admin/users/edit")))
const SemuaProyek=lazy(()=>(import("./pages/admin/proyek/semua_proyek")))
const AddProyek=lazy(()=>(import("./pages/admin/proyek/add_proyek")))
const AddTugas=lazy(()=>(import("./pages/admin/proyek/add_tugas")))
const DetailTugas=lazy(()=>(import("./pages/admin/proyek/detail")))
const TimelineTugas=lazy(()=>(import("./pages/admin/timeline/timeline_tugas")))
const Absensi=lazy(()=>(import("./pages/admin/absensi")))
const KeyPerformIndicator=lazy(()=>(import("./pages/admin/kpi")))
const ActivityStaff=lazy(()=>(import("./pages/admin/activity_staff")))

class App extends React.Component{
    state={
        install_prompt:false,
        deferred_prompt:""
    }

    componentDidMount=()=>{
        register()

        const fireAddToHomeScreenImpression=e=>{
            e.preventDefault()
            this.setState({
                install_prompt:true,
                deferred_prompt:e
            })
        }
        window.addEventListener("beforeinstallprompt", fireAddToHomeScreenImpression)
    }

    showAddToHomeScreen=async()=>{
        this.state.deferred_prompt.prompt()
        let outcome = await this.state.deferred_prompt.userChoice
        
        if(outcome==='accepted') console.log('User accepted the A2HS prompt')
        else console.log('User dismissed the A2HS prompt')

        this.setState({
            deferred_prompt:null
        })
    }

    render(){
        return (
            <>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <Route exact path="/" component={Login}/>
                        <Route exact path="/register" component={Register}/>
                        <Route exact path="/admin" component={Dashboard}/>
                        <Route exact path="/admin/profile" component={Profile}/>
                        <Route exact path="/admin/jabatan" component={Jabatan}/>
                        <Route exact path="/admin/jabatan/add" component={AddJabatan}/>
                        <Route exact path="/admin/users" component={Users}/>
                        <Route exact path="/admin/users/add" component={AddUser}/>
                        <Route exact path="/admin/users/edit" component={EditUser}/>
                        <Route exact path="/admin/proyek/semua_proyek" component={SemuaProyek}/>
                        <Route exact path="/admin/proyek/add_proyek" component={AddProyek}/>
                        <Route exact path="/admin/proyek/add_tugas" component={AddTugas}/>
                        <Route exact path="/admin/proyek/detail" component={DetailTugas}/>
                        <Route exact path="/admin/timeline/timeline_tugas" component={TimelineTugas}/>
                        <Route exact path="/admin/absensi" component={Absensi}/>
                        <Route exact path="/admin/kpi" component={KeyPerformIndicator}/>
                        <Route exact path="/admin/activity_staff" component={ActivityStaff}/>
                    </Switch>
                </Suspense>
                <div style={{display:this.state.install_prompt?"block":"none", width:"100%", position:"fixed", bottom:"0", zIndex:"9999999", background:"#fff", boxShadow:"0 0 5px rgba(0, 0, 0, .1)"}}>
                    <div className="d-flex align-items-center justify-content-center p-3">
                        <h4 style={{fontSize:"18px", fontWeight:"600"}} className="text-center">Install Aplikasi Garapan ID di perangkat anda!</h4>
                    </div>
                    <div className="d-flex align-items-center justify-content-center py-3">
                        <button className="btn btn-white" onClick={()=>this.setState({install_prompt:false})}>Batal</button>
                        <button className="btn btn-primary ml-2" onClick={this.showAddToHomeScreen}>Install</button>
                    </div>
                </div>
            </>
        )
    }
}

export default App