import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n"

//component
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from "react-toastify";

//styles
import "./styles/tabler.min.css"
import 'react-toastify/dist/ReactToastify.css'
import "./styles/globals.css"
import "react-big-calendar/lib/css/react-big-calendar.css"

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App/>
      <ToastContainer
        hideProgressBar={true}
        transition={Slide}
        hideProgressBar={true}
      />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
