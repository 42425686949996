export const register=()=>{
    if('serviceWorker' in navigator){
        navigator.serviceWorker.register("/service-worker.js")
        .then(sw=>{
          console.log("service worker registered")
        })
        .catch(e=>{
          console.log("service worker error : ", e)
        })
    }
}